// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://devenv.api.mazboletos.com',
  fnGetUser:
    'https://faas-sfo3-7872a1dd.doserverless.co/api/v1/web/fn-3faa3f84-dd9f-49a8-85d4-20195c66e4c4/get-user/get-user',
  fnScanTicket: 'https://fns.devenv.mazboletos.com/mazboletos/scan',
  fnGetUserToken: 'Vo6fevU1z3McxjH',
  fnScanTicketToken: 'Xx8ztTHDvkLFmXe',
  firebase: {
    apiKey: 'AIzaSyA6F3T1NPT-mRIhfslT-lNj-2a2mQoYlLI',
    authDomain: 'mazboletos-box-office.firebaseapp.com',
    databaseURL: 'https://mazboletos-box-office.firebaseio.com',
    projectId: 'mazboletos-box-office',
    storageBucket: '',
    messagingSenderId: '966842575203',
    fnScanTicketToken: 'Bk6yBS5EkTEPV6T',
    fnGetUserToken: 'Lm40j0aBvmiNzcn',
  },
  imageKitUrl: 'https://ik.imagekit.io/seatix/mazboletos',
  mentaTech: {
    myEventsUrl: 'https://my-events.mentatickets.com',
    ticketInfoUrl: 'https://ticket-info.mentatickets.com',
    checkoutUrl: 'https://checkout.mentatickets.com',
    qrlinkUrl: 'https://qrlink.mentatickets.com',
    sellTicketUrl: 'https://sell-ticket.mentatickets.com',
    authUrl: 'https://auth.mentatickets.com',
    eventUrl: 'https://event.mentatickets.com',
    ticketsUrl: 'https://tickets.mentatickets.com',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
